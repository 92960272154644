export const countries = [
  {
    value: 'ae',
    apiLabel: 'United Arab Emirates',
    labelEn: 'UAE',
    labelAr: 'الإمارات',
    icon: 'united-arab-emirates',
    localizable: true,
    public: true,
    sorter: 'forest_UAE_stock_sorter',
    excludeAttribute: 'exclude_in_ae',
    linkDefault: '/en-ae/',
    linkEn: '/en-ae/',
    linkAr: '/ar-ae/',
    code: '+971'
  },
  {
    value: 'qa',
    apiLabel: 'QATAR',
    labelEn: 'QATAR',
    labelAr: 'دولة قطر',
    icon: 'qatar',
    localizable: false,
    public: false,
    sorter: 'forest_QATAR_stock_sorter',
    excludeAttribute: 'exclude_in_qa',
    linkDefault: '/ar-qa/',
    linkEn: '/en-qa/',
    linkAr: '/ar-qa/',
    code: '+974'
  },
  {
    value: 'sa',
    apiLabel: 'SAUDI ARABIA',
    labelEn: 'KSA',
    labelAr: 'السعودية',
    icon: 'saudi-arabia',
    localizable: false,
    public: false,
    sorter: 'forest_KSA_stock_sorter',
    excludeAttribute: 'exclude_in_sa',
    linkDefault: '/ar-sa/',
    linkEn: '/en-sa/',
    linkAr: '/ar-sa/',
    code: '+966'
  },
  {
    value: 'om',
    apiLabel: 'OMAN',
    labelEn: 'OMAN',
    labelAr: 'سلطنة عمان',
    icon: 'oman',
    localizable: false,
    public: false,
    sorter: 'forest_OMAN_stock_sorter',
    excludeAttribute: 'exclude_in_om',
    linkDefault: '/ar-om/',
    linkEn: '/en-om/',
    linkAr: '/ar-om/',
    code: '+968'
  },
  {
    value: 'kw',
    apiLabel: 'KUWAIT',
    labelEn: 'KUWAIT',
    labelAr: 'الكويت',
    icon: 'kuwait',
    localizable: true,
    public: false,
    sorter: 'forest_KUWAIT_stock_sorter',
    excludeAttribute: 'exclude_in_kw',
    linkDefault: '/ar-kw/',
    linkEn: '/en-kw/',
    linkAr: '/ar-kw/',
    code: '+965'
  },
  {
    value: 'bh',
    apiLabel: 'BAHRAIN',
    labelEn: 'BAHRAIN',
    labelAr: 'البحرين',
    icon: 'bahrain',
    localizable: false,
    public: false,
    sorter: 'forest_BAHRAIN_stock_sorter',
    excludeAttribute: 'exclude_in_bh',
    linkDefault: '/ar-bh/',
    linkEn: '/en-bh/',
    linkAr: '/ar-bh/',
    code: '+973'
  }
];

export const countrySorterMap = {
  ae: 'forest_UAE_stock_sorter',
  qa: 'forest_QATAR_stock_sorter',
  sa: 'forest_KSA_stock_sorter',
  om: 'forest_OMAN_stock_sorter',
  kw: 'forest_KUWAIT_stock_sorter',
  bh: 'forest_BAHRAIN_stock_sorter'
};

export const countryCurrencyMap = {
  ae: 'aed',
  qa: 'qar',
  sa: 'sar',
  om: 'omr',
  kw: 'kwd',
  bh: 'bhd'
};

export const countryExcludeAttributeMap = {
  // {"products.attributes_exclude_in_ae":[true]}
  ae: 'eyJwcm9kdWN0cy5hdHRyaWJ1dGVzX2V4Y2x1ZGVfaW5fYWUiOlt0cnVlXX0=',
  // {"products.attributes_exclude_in_qa":[true]}
  qa: 'eyJwcm9kdWN0cy5hdHRyaWJ1dGVzX2V4Y2x1ZGVfaW5fcWEiOlt0cnVlXX0=',
  // {"products.attributes_exclude_in_sa":[true]}
  sa: 'eyJwcm9kdWN0cy5hdHRyaWJ1dGVzX2V4Y2x1ZGVfaW5fc2EiOlt0cnVlXX0=',
  // {"products.attributes_exclude_in_om":[true]}
  om: 'eyJwcm9kdWN0cy5hdHRyaWJ1dGVzX2V4Y2x1ZGVfaW5fb20iOlt0cnVlXX0=',
  // {"products.attributes_exclude_in_kw":[true]}
  kw: 'eyJwcm9kdWN0cy5hdHRyaWJ1dGVzX2V4Y2x1ZGVfaW5fa3ciOlt0cnVlXX0=',
  // {"products.attributes_exclude_in_bh":[true]}
  bh: 'eyJwcm9kdWN0cy5hdHRyaWJ1dGVzX2V4Y2x1ZGVfaW5fYmgiOlt0cnVlXX0='
};
