enum GENERAL_ROUTES {
  HOME = '/',
  BASKET = '/baskets/basket',
  LIST = '/list'
}

enum AUTH_ROUTES {
  AUTH = '/users/auth',
  LOGIN = '/users/auth/login',
  REGISTER = '/users/auth/register',
  FORGOT_PASSWORD = '/users/password/reset'
}

enum ACCOUNT_ROUTES {
  ACCOUNT = '/account',
  ACCOUNT_ADDRESS = '/account/address',
  ACCOUNT_CHANGE_EMAIL = '/account/change-email',
  ACCOUNT_CHANGE_PASSWORD = '/account/change-password',
  ACCOUNT_CONTACT = '/account/contact',
  ACCOUNT_COUPONS = '/account/coupons',
  ACCOUNT_FAQ = '/account/faq',
  ACCOUNT_ORDERS = '/users/orders',
  ACCOUNT_WISHLIST = '/account/favourite-products/',
  ACCOUNT_EDIT_INFORMATION = '/account/edit-information',
  ACCOUNT_STORE_CREDIT = '/account/store-credit/'
}

enum ORDER_ROUTES {
  CHECKOUT = '/orders/checkout',
  CHECKOUT_COMPLETED = '/orders/completed'
}

enum FLATPAGE_ROUTES {
  CONTACT_US = '/contact-us',
  SOCIAL_RESPONSIBILITY_AND_SUSTAINABILITY = '/social-responsibility-and-sustainability',
  MAKEUP = '/natural-makeup',
  COLLECTIONS = '/collections',
  TRAVEL_KITS = '/travel-kits',
  ABOUT_US = '/about-us',
  SHARE = '/share',
  FAQ = '/faq/'
}

enum PRODUCT_ROUTES {}

export const ROUTES = {
  ...GENERAL_ROUTES,
  ...AUTH_ROUTES,
  ...ACCOUNT_ROUTES,
  ...ORDER_ROUTES,
  ...FLATPAGE_ROUTES,
  ...PRODUCT_ROUTES
};
