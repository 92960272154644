import { asTypedObject } from '@akinon/next/utils';
import { ImageOptions, Order, OrderItem } from '@akinon/next/types';
import { countrySorterMap } from 'countries';

const calcDistance = (lat1, lon1, lat2, lon2) => {
  if ([lat1, lon1, lat2, lon2].some((v) => !v)) {
    return null;
  }

  const R = 6371; // km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    0.5 -
    Math.cos(dLat) / 2 +
    (Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      (1 - Math.cos(dLon))) /
      2;

  return R * 2 * Math.asin(Math.sqrt(a));
};

export { calcDistance };

export const createEcommerceCategoryItem = ({
  product,
  index,
  hasSearchResultPage = false
}) => {
  return ({
    item_name: product?.name ?? '',
    item_id: `${product.base_code}_${
      product?.attributes?.filterable_color ||
      product?.attributes?.color ||
      ''
    }`,
    price:
      +product?.retail_price > +product?.price
        ? +product?.price
        : +product?.retail_price,
    item_brand: product?.attributes?.brand || '',
    item_variant: product?.attributes?.ml || '',
    item_category: product?.attributes?.cat_lev_3 || '',
    item_category2: product?.attributes?.cat_lev_2 || '',
    item_category3: product?.attributes?.cat_lev_1 || '',
    retailPrice: +product?.retail_price ?? 0,
    item_list_id:
      product?.attributes_kwargs?.integration_type?.label ||
      product?.attributes_kwargs?.gender?.label ||
      '',
    item_list_name: hasSearchResultPage ? 'Search Result' : 'Product List',
    image: product?.productimage_set[0]?.image || '',
    index
  })
}

export const createEcommerceItem = ({
  product,
  quantity = 1,
  index = 1,
  orderItemRetailPrice = 0,
  orderItemPrice = 0,
  isShowStock = true,
  pageType = 'Product List'
}) => {
  const retail_price =
    (product?.retail_price && Number(product?.retail_price)) ||
    Number(orderItemRetailPrice);
  const price =
    Number(orderItemPrice) || (product?.price && Number(product?.price));
  const item_brand = product?.attributes?.brand ?? '';
  const gender = product?.attributes_kwargs?.gender?.label ?? '';
  const type = product?.attributes_kwargs?.integration_type?.label ?? '';
  const item_category = product?.attributes?.cat_lev_3 ?? '';
  const item_category2 = product?.attributes?.cat_lev_2 ?? '';
  const item_category3 = product?.attributes?.cat_lev_1 ?? '';
  const item_category4 = 'Homepage';
  const item_list_id = type || gender;
  const item_list_name =
    pageType === 'Product List'
      ? gender && type
        ? `${pageType} - ${gender} ${type}`
        : type || gender
      : pageType;
  const discount = (retail_price - price) || 0;
  const discount_percent = (Number(discount) / retail_price) * 100 || 0;

  const data = {
    item_id: `${product?.base_code}_${
      product?.attributes?.filterable_color || product?.attributes?.color || ''
    }`,
    child_sku: product?.sku,
    item_name: product?.name,
    coupon: '',
    discount,
    index,
    item_brand,
    item_category,
    item_category2,
    item_category3,
    item_category4,
    item_list_id,
    item_list_name,
    item_variant: product?.attributes?.ml ?? '',
    price:
      Number(retail_price) > Number(price)
        ? price
        : retail_price,
    quantity,
    sale_status: Number(retail_price) > Number(price) ? 'On Offer' : 'No Offer',
    discount_percent,
    discount_price: price,
    product_gender: gender,
  }

  if (isShowStock) {
    data['stock_status'] = product?.stock > 0 ? 'In Stock' : 'Out of Stock';
  }

  return data;
};

const asImageOptions = asTypedObject<ImageOptions>();

export const IMAGE_OPTIONS = asImageOptions({
  // Account
  'account-order-list': { width: 64, height: 96, crop: 'center' },
  'account-order-return': { width: 105, height: 105, crop: 'center' },
  'account-order-detail': { width: 90, height: 90, crop: 'center' },
  'account-favourite-item': {
    sources: [
      {
        media: 'min-width: 1370px',
        options: { width: 352, height: 512, crop: 'center' }
      },
      {
        media: 'min-width: 1170px',
        options: { width: 260, height: 384, crop: 'center' }
      },
      {
        media: 'min-width: 1024px',
        options: { width: 212, height: 308, crop: 'center' }
      },
      {
        media: 'min-width: 768px',
        options: { width: 356, height: 345, crop: 'center' }
      },
      {
        media: 'min-width: 475px',
        options: { width: 264, height: 384, crop: 'center' }
      },
      {
        media: 'min-width: 425px',
        options: { width: 216, height: 320, crop: 'center' }
      }
    ],
    width: 192,
    height: 280,
    crop: 'center'
  },
  // Basket
  'basket-list-item': {
    sources: [
      {
        media: 'min-width: 575px',
        options: { width: 96, height: 160, crop: 'center' }
      }
    ],
    width: 80,
    height: 128,
    crop: 'center'
  },
  // Basket - Mini
  'mini-basket-list-item': { width: 96, height: 160, crop: 'center' },
  // Checkout
  'checkout-funds-transfer-bank-logo': { height: 24, crop: 'center' },
  'checkout-summary-list-item': { width: 64, height: 96, crop: 'center' },
  'checkout-success-list-item': { width: 64, height: 96, crop: 'center' },
  // List
  'product-list-layout-3': {
    sources: [
      {
        media: 'min-width: 1370px',
        options: { width: 316, height: 316, crop: 'center' }
      },
      {
        media: 'min-width: 1170px',
        options: { width: 316, height: 316, crop: 'center' }
      },
      {
        media: 'min-width: 768px',
        options: { width: 316, height: 316, crop: 'center' }
      },
      {
        media: 'min-width: 425px',
        options: { width: 168, height: 168, crop: 'center' }
      }
    ],
    width: 316,
    height: 316,
    crop: 'center'
  },
  'product-list-layout-2': {
    sources: [
      {
        media: 'min-width: 1370px',
        options: { width: 528, height: 768, crop: 'center' }
      },
      {
        media: 'min-width: 1170px',
        options: { width: 400, height: 576, crop: 'center' }
      },
      {
        media: 'min-width: 768px',
        options: { width: 340, height: 496, crop: 'center' }
      },
      {
        media: 'min-width: 425px',
        options: { width: 288, height: 416, crop: 'center' }
      }
    ],
    width: 192,
    height: 272,
    crop: 'center'
  },
  // Product
  'product-slider-thumb': { width: 88, height: 88, crop: 'center' },
  'product-gallery': {
    sources: [
      {
        media: 'min-width: 1024px',
        options: { width: 400, height: 400, crop: 'center' }
      },
      {
        media: 'min-width: 768px',
        options: { width: 400, height: 400, crop: 'center' }
      },
      {
        media: 'min-width: 601px',
        options: { width: 400, height: 400, crop: 'center' }
      },
      {
        media: 'min-width: 425px',
        options: { width: 352, height: 352, crop: 'center' }
      }
    ],
    width: 352,
    height: 352,
    crop: 'center'
  },
  'product-gallery-zoom': { width: 1440, height: 2160, crop: 'center' },
  'product-gallery-thumb': { width: 48, height: 80, crop: 'center' },
  'product-variant': { width: 48, height: 72, crop: 'center' },
  'product-combine-thumbnail': { width: 144, height: 224, crop: 'center' },
  // Store
  'store-list-item': { width: 256, height: 192, crop: 'center' },
  // Widget
  'widget-discovery-cover': {
    sources: [
      {
        media: 'min-width: 1370px',
        options: { width: 432, height: 304, crop: 'center' }
      },
      {
        media: 'min-width: 1170px',
        options: { width: 320, height: 224, crop: 'center' }
      }
    ],
    width: 272,
    height: 192,
    crop: 'center'
  },
  'widget-product-recommendation': {
    sources: [
      {
        media: 'min-width: 1370px',
        options: { width: 272, height: 416, crop: 'center' }
      },
      {
        media: 'min-width: 1170px',
        options: { width: 224, height: 336, crop: 'center' }
      },
      {
        media: 'min-width: 1024px',
        options: { width: 192, height: 288, crop: 'center' }
      },
      {
        media: 'min-width: 640px',
        options: { width: 144, height: 224, crop: 'center' }
      },
      {
        media: 'min-width: 425px',
        options: { width: 192, height: 288, crop: 'center' }
      }
    ],
    width: 128,
    height: 192,
    crop: 'center'
  },
  'widget-stories': {
    sources: [
      {
        media: 'min-width: 768px',
        options: { width: 140, height: 140, crop: 'center' }
      }
    ],
    width: 112,
    height: 112,
    crop: 'center'
  }
});

export const getOrderStatus = (
  itemStatus: string,
  t: (path: string) => string
) => {
  const result = {
    label: '',
    className: 'text-black'
  } as {
    label: string;
    className?: string;
  };

  if (itemStatus === '50') {
    result.label = t('account.my_orders.status.cancel_requested');
  } else if (itemStatus === '100') {
    result.label = t('account.my_orders.status.cancelled');
  } else if (itemStatus === '200') {
    result.label = t('account.my_orders.status.order_waiting');
  } else if (itemStatus === '300') {
    result.label = t('account.my_orders.status.payment_waiting');
  } else if (itemStatus === '350') {
    result.label = t('account.my_orders.status.confirmation_waiting');
  } else if (itemStatus === '400') {
    result.label = t('account.my_orders.status.approved');
  } else if (itemStatus === '450') {
    result.label = t('account.my_orders.status.preparing');
  } else if (itemStatus === '500') {
    result.label = t('account.my_orders.status.shipped');
  } else if (itemStatus === '510') {
    result.label = t('account.my_orders.status.shipped_and_informed');
  } else if (itemStatus === '520') {
    result.label = t('account.my_orders.status.ready_for_pickup');
  } else if (itemStatus === '540') {
    result.label = t('account.my_orders.status.attempted_delivery');
  } else if (itemStatus === '550') {
    result.label = t('account.my_orders.status.delivered');
  } else if (itemStatus === '600') {
    result.label = t('account.my_orders.status.returned');
  }

  if (
    itemStatus === 'cancel' ||
    itemStatus === '100' ||
    itemStatus === '600' ||
    itemStatus === 'refund'
  ) {
    result.className = 'text-red-600';
  }

  if (
    itemStatus === '400' ||
    itemStatus === '450' ||
    itemStatus === '500' ||
    itemStatus === '550'
  ) {
    result.className = 'text-green';
  }

  return result;
};

export const setCountrySorter = (searchParams, country) => {
  const sorter = countrySorterMap[country];
  const sorterParam = searchParams.sorter;

  if (sorterParam && sorterParam !== 'default') {
    return searchParams;
  }

  searchParams.sorter = sorter;

  return searchParams;
};

interface OrderData extends Order {
  active_cancellation_request?: boolean;
  invoice_number?: string;
}

interface OrderItemData extends OrderItem {
  isCancelButtonVisible?: boolean;
  hasDiscount?: boolean;
  cancelButtonLabel?: string;
  realItemStatusLabel?: string;
}

const notCancellableStatuses = ['100', '600', '500'];

const processOrderItems = (orderItems: OrderItem[], t: (path: string) => string
  ):OrderItemData[] => {
  return orderItems.map((item:OrderItem) => {
    return {
      ...item,
      cancelButtonLabel: item.is_refundable ? t('account.my_orders.detail.return_this_item') : item.is_cancellable && t('account.my_orders.detail.cancel_this_item'),
      isCancelButtonVisible: (item.is_cancellable || item.is_refundable) && !item.active_cancellation_request && !notCancellableStatuses.includes(item.status.value),
      realItemStatusLabel: (!item.active_cancellation_request || notCancellableStatuses.includes(item.status.value)) ? item.status.label : t('account.my_orders.status.cancel_requested'),
      hasDiscount: item.retail_price && parseFloat(item.retail_price) > parseFloat(item.price),
    };
  });
};

const groupOrder = (orderItems:OrderItemData[], groupKey = 'order') => {
  /**
   * This function groups the order items by the given key.
   * Example usage: 
   * const groupedOrder = groupOrder(orderItems,process.env.NEXT_PUBLIC_ORDER_GROUP_KEY),
   */
  
  return orderItems.reduce((result, item) => {
    const key = item[groupKey];
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }
  , {});
};

export const processOrderData = (
  order: OrderData, 
  t:(path: string) => string
  ) => {

  // Order statuses
  // 100: Cancelled
  // 400: Approved
  // 450: Preparing
  // 500: Shipped
  // 550: Delivered
  // 600: Refunded

  // This block of code determines the type of button to be displayed based on the order status.
  // If an invoice number exists, the status code is checked to determine the button type:
  // If the status code is 550, the button type is 'return request'.
  // If the status code is between 500 and 550, the button type is 'TRACK SHIPMENT'.
  // If the status code is less than 500, no button is displayed.
  // If invoice number doesn't exists, the button type is 'cancel order'.
  const statusButton = {
    label: '',
    style: '',
    type: ''
  };

  if(order?.invoice_number) {
    const statusCode = parseInt(order.status?.value);

    switch(true) {
      case statusCode === 550:
        statusButton.label = t('account.my_orders.detail.return_order');
        statusButton.style = 'bg-secondary text-white hover:bg-secondary-500';
        statusButton.type = 'return_order';
        break;
      case statusCode < 550 && statusCode > 500:
        statusButton.label = t('account.my_orders.detail.track_shipment');
        statusButton.style = '';
        statusButton.type = 'track_order';
        break;
      case statusCode < 550:
        break;
      default:
        break;
    }
  }else{
    statusButton.label = t('account.my_orders.detail.cancel_order');
    statusButton.style = '';
    statusButton.type = 'cancel_order';
  }

  let orderItems:OrderItemData[] = [...order.orderitem_set];

  const CODIndex = orderItems.findIndex((item) =>
    item?.product.base_code?.toUpperCase().includes('COD')
  );

  if (CODIndex > -1) {
    orderItems.splice(CODIndex, 1);
  };

  orderItems = processOrderItems(orderItems,t);

  const isOrderDelivered = parseInt(order.status?.value) === 550;
  const isOrderCancellable = !orderItems.every((item) => !item.isCancelButtonVisible);
  const realOrderStatusLabel = !notCancellableStatuses.includes(order.status?.value) && !isOrderCancellable ? t('account.my_orders.status.cancel_requested') : order.status?.label;

  return {
    createdDate : order.created_date,
    totalAmount: order.amount,
    orderNumber: order.number,
    orderId: order.id,
    orderItems,
    realOrderStatusLabel,
    statusButtonLabel: statusButton.label,
    statusButtonStyle: statusButton.style,
    statusButtonType: statusButton.type,
    cancellationURL: `/account/orders/${order.id}/cancellation`,
    detailURL: `/account/orders/${order.id}`,
    trackingURL: order.tracking_url,
    CODAmount: CODIndex > -1 ? order.orderitem_set[CODIndex].price : 0,
    orderItemsLength: orderItems.length,
    isStatusButtonVisible: isOrderCancellable && statusButton.label !== '',
    isTrackingButtonVisible: order.tracking_number && order.tracking_url,
    cancellationButtonLabel: isOrderDelivered ? t('account.my_orders.return.return_selected_items') : t('account.my_orders.cancel.cancel_selected_items'),
    cancellationSummaryTitle: isOrderDelivered ? t('account.my_orders.return.title') : t('account.my_orders.cancel.title'),
    ...order
  };
}
